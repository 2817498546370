import "./App.css";
import Home from "./components/home/home";
import Navbar from "./components/navbar/navbar";
import AllEvents from "./components/allEvents/allEvents";
import BeAMember from "./components/BecomeMember/beAMember";
import Footer from "./components/footer/footer";
import UpcomingEvents from "./components/upcomingEvents/upcomingEvents";
import Login from "./components/login/Login";
import Membership from "./components/Membership/Membership";
import MembershipForm from "./components/MembershipForm/MembershipForm";
import EventsFacilityCheckout from "./components/EventsFacility/EventsFacilityCheckout/EventsFaciltiyCheckout";
import Advisiors from "./components/Advisiors/Advisiors";
import ReviewMessage from "./components/Confirm/ReviewMessage";
import CheckOut from "./components/CheckOut/CheckOut";
import EventsFacility from "./components/EventsFacility/EventsFacility";
import SportClubForm from "./components/Sports/SportClubForm";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import EventsFacilityBookingConfirmed from "./components/EventsFacility/EventsFacilityBookingConfirmed/EventsFacilityBookingConfirmed";
import Logins from "./components/Login2/Login";
import { Suspense, lazy, useEffect, useState } from "react";
import ViewAct from "./components/Sports/view-details/ViewAct";
import Fnb from "./components/EventsFacility/fnb/Fnb";
import FNBFacilityBooking from "./components/EventsFacility/fnbBooking/FNBFacilityBooking";
import JoinUs from "./components/join-us/JoinUs";
import { Helmet } from "react-helmet";
import EditProfile from "./components/EditProfile/EditProfile";
import Email from "./components/login/Email";
import ConfirmPassword from "./components/login/ConfirmPassword";
import axios from "axios";
import MembershipCheckout from "./components/Payments/MembershipCheckout";
import BeAMemberSmallComponent from "./components/BeAMemberSmallComponent/BeAMemberSmallComponent";
import RedDotBanner from "./components/RedDot/RedDotBanner";
import FailPayment from "./ShareComponents/FailPayment";
import SuccessPayment from "./ShareComponents/SuccessPayment";
// import { useLocation } from 'react-router-dom';

const AboutUs = lazy(() => import("./components/aboutUs/aboutUs"));
const MgmtCommittee = lazy(()=> import("./components/MgmtCommittee/MgmtCommittee"));
const Gallary = lazy(()=> import("./components/gallary/gallary"));
const History = lazy(()=> import("./components/history/history"));
const GeneralOffice = lazy(()=> import("./components/general_office/GeneralOffice"));
const WomenWings = lazy(()=> import("./components/WomenWings/WomenWings"));
const Constitution = lazy(()=> import("./components/Constitution/Constitution"));
const SportsClub = lazy(()=> import("./components/Sports/SportsClub"));
const Eventss = lazy(()=> import("./components/upcomingEvents/Events"));
const GalleryFolder = lazy(()=> import("./components/gallary/GalleryFolder"));
const EventsFacilityBooking = lazy(()=> import("./components/EventsFacility/EventsFacilityBookings/EventsFacilityBooking"));
const SportsFacility = lazy(()=> import("./components/Sports/SportsFacility"));
const ContactUs = lazy(()=> import("./components/ContactUs/ContacUs"));
const MemberProfilePage = lazy(()=> import("./components/Membership/MemberProfilePage"));
const ViewDetails = lazy(()=> import("./components/Sports/view-details/ViewDetails"));
const Events = lazy(()=> import("./components/events/events"));
const AllEventsFacilityBooking = lazy(() => import("./components/EventsFacility/AllEventsFacilityBookings/AllEventsFacilityBooking"));
const SportsFacilityDetails = lazy(() => import("./components/Sports/SportsFacilityDetails"));
const SportsClubDetails = lazy(() => import("./components/Sports/SportsClubDetails"));
const EditMembershipDetails = lazy(() => import("./components/MembershipForm/EditMembershipDetails"));


function App() {
  // const [user, setUser] = useState(false);
  const [headerStatus, setHeaderStatus] = useState(false);
  const [currentUrl, setCurrentUrl] = useState("");
  const [ogData, setOgData] = useState({
    ogTitle:'',
    ogDesc:'',
    ogImage:''
  })

  useEffect(() => {
    setCurrentUrl(window.location.pathname + window.location.search);
  }, [ogData]);

  useEffect(() => {
    let title = ogData.ogTitle ? ogData.ogTitle : "SGIA";
    let description = ogData.ogDesc ? ogData.ogDesc :
      "The Singapore Indian Association was established in 1923 with the objective of promoting the social, physical, intellectual, cultural and the general welfare of its members.";
    let image = ogData.ogImage ? ogData.ogImage :
      "https://mysiabucket123.s3.ap-southeast-1.amazonaws.com/sia/new_member/images/08rHLpKrs00ZVHijCY_2D.png";

    // Update metadata based on the current URL
    if (currentUrl === "/") {
      title = "SGIA Website";
      description = "Welcome to the SGIA Website";
      image =
        "https://mysiabucket123.s3.ap-southeast-1.amazonaws.com/sia/new_member/images/08rHLpKrs00ZVHijCY_2D.png";
    } else if (currentUrl === "/events") {
      const params = new URLSearchParams(window.location.search);
      const eventName = params.get("id");
      title = eventName; 
 
    } else if (currentUrl === "/SportsFacilityDetails") {
      const params = new URLSearchParams(window.location.search);
      const facilityId = params.get("id");
      // Set other sport facility-specific metadata based on your data
    } else if (currentUrl === "/gallery/view") {
      const params = new URLSearchParams(window.location.search);
      const albumId = params.get("id");
      // Set other photo album-specific metadata based on your data
    }

    // Update the meta tags dynamically
    const metaTags = document.getElementsByTagName("meta");
    Array.from(metaTags).forEach((tag) => {
      if (tag.getAttribute("name") === "description") {
        tag.setAttribute("content", description);
      } else if (tag.getAttribute("property") === "og:title") {
        tag.setAttribute("content", title);
      } else if (tag.getAttribute("property") === "og:description") {
        tag.setAttribute("content", description);
      } else if (tag.getAttribute("property") === "og:image") {
        tag.setAttribute("content", image);
      } else if (tag.getAttribute("property") === "og:url") {
        tag.setAttribute("content", window.location.href);
      }
    });

    // document.title = title;
    console.log(metaTags[7]);
  }, [currentUrl]);

  // useEffect(() => {
  //   if (localStorage.getItem("user")) {
  //     setUser(true);
  //   }
  // }, []);

  axios.interceptors.response.use(
   function(response){
      return response;
    
   },
    function(error){
      console.log(error.response.data.errors[0].message);
      if(error.response.data.errors[0].message==="E_UNAUTHORIZED_ACCESS: Unauthorized access"){
        localStorage.removeItem('token');
        localStorage.removeItem('status');
        localStorage.removeItem('phone');
        localStorage.removeItem('email');
        localStorage.removeItem('name');
      
        // window.location.reload()
      }
      throw error;
    }

  )

  return (
    <Router>
      <Helmet>
        {/* Additional meta tags */}
        <meta name="description" content="Default Description" />
        <meta property="og:title" content="Default Title" />
        <meta property="og:description" content="Default Description" />
        <meta
          property="og:image"
          content="http://example.com/default-image.jpg"
        />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
      </Helmet>

      <Navbar headerStatus={headerStatus} />
      {/* <Navbar headerStatus={headerStatus} /> */}
      <Suspense fallback={<h1>Loading...</h1>}>
      <Routes>
        {/* {user ? ( */}
          <Route path="/" element={<Home setOgData={setOgData} />} />
        {/* ) 
        : (
          <Route path="/" element={<Logins />} />
        )} */}
        <Route path="/events" element={<Events setOgData={setOgData} />} />
        <Route path="/membershipCheckout" element={<MembershipCheckout />} />
        <Route path="/SportsSection/join-us" element={<JoinUs />} />
        <Route path="/constitution" element={<Constitution />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/be-a-member" element={<BeAMember />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/Membership" element={<Membership />} />
        <Route path="/renewalMembership" element={<Membership />}/>
        <Route path="/EditMembershipDetails" element={<EditMembershipDetails />} />
        <Route path="/MembershipForm" element={<MembershipForm />} />
        <Route path="/Advisiors" element={<Advisiors />} />
        <Route path="/review_message" element={<ReviewMessage />} />F
        <Route path="/Checkout" element={<CheckOut />} />
        <Route path="/EventsFacility" element={<EventsFacility />} />
        <Route
          path="/EventsFacilityCheckout"
          element={<EventsFacilityCheckout />}
        />
        <Route
          path="/EventsFacilityBookingConfirmed"
          element={<EventsFacilityBookingConfirmed />}
        />
        <Route path="/membershipBookingConfirmed" element={<SuccessPayment/>}/>
        <Route path="/SportsSection" element={<SportsClub />} />
        <Route path="/SportsSection/view-details" element={<ViewDetails />} />
        <Route path="/SportsSection/view-details/activities-details" element={<ViewAct />} />
        <Route path="/SportsClubForm" element={<SportClubForm />} />
        <Route
          path="/EventsFacilityBooking"
          element={<EventsFacilityBooking />}
        />
        <Route path="/presidentialLoungeFacilityBooking" element={<FNBFacilityBooking />} />
        <Route
          path="/AllEventsFacilityBooking"
          element={<AllEventsFacilityBooking />}
        />
        <Route path="/presidentialLounge" element={<Fnb />} />
        <Route path="/SportsClubDetails" element={<SportsClubDetails />} />
        <Route path="/SportsFacility" element={<SportsFacility />} />
        <Route
          path="/SportsFacilityDetails"
          element={<SportsFacilityDetails setOgData={setOgData} />}
        />
        {/* <Route path="/footer" element={<Footer />} /> */}
        <Route
          path="/MemberProfilePage"
          element={<MemberProfilePage setHeaderStatus={setHeaderStatus} />}
        />
        <Route path="/management-committee" element={<MgmtCommittee />} />
        <Route path="/general-office" element={<GeneralOffice />} />
        <Route path="/women-wings" element={<WomenWings />} />
        <Route path="/history" element={<History />} />
        <Route path="/edit-profile" element={<EditProfile />} />
        <Route path="/upcoming-events" element={<Eventss />} />
        {/* <Route path="/upcoming-events-2" element={<AllEvents />} /> */}
        <Route path="/gallery" element={<Gallary />} />
        <Route path="/gallery/:name" element={<Gallary />} />
        <Route path="/gallery/view" element={<Gallary setOgData={setOgData} />} />
        <Route path="/galleryFolder" element={<GalleryFolder />} />
        <Route path="/login" element={<Login />} />
        <Route path="/login/v2/verify-password" element={<ConfirmPassword />} />
          <Route path="/failpayment" element={<FailPayment />} />
        </Routes>
        </Suspense>
      <BeAMemberSmallComponent/>
       <Footer />
      <RedDotBanner/>
      {/* <Footer /> */}
    </Router>
  );
}

export default App;
